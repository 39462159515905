import { useState, useEffect } from 'react';
import styles from './index.module.scss';

export const LoadingSpinner = () => {
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowSpinner(true);
        }, 200);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return showSpinner ? <div className={styles.spinner} /> : null;
};
