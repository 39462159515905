export const CardHolderIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            style={{
                marginTop: '-20px',
                marginLeft: '-10px',
            }}
        >
            <g clipPath="url(#clip0_7_6145)">
                <path
                    opacity="0.2"
                    d="M40 47.5C42.6522 47.5 45.1957 46.4464 47.0711 44.5711C48.9464 42.6957 50 40.1522 50 37.5H70V22.5C70 21.1739 69.4732 19.9021 68.5355 18.9645C67.5979 18.0268 66.3261 17.5 65 17.5H15C13.6739 17.5 12.4021 18.0268 11.4645 18.9645C10.5268 19.9021 10 21.1739 10 22.5V37.5H30C30 40.1522 31.0536 42.6957 32.9289 44.5711C34.8043 46.4464 37.3478 47.5 40 47.5Z"
                    fill="black"
                />
                <path
                    d="M65 17.5H15C12.2386 17.5 10 19.7386 10 22.5V57.5C10 60.2614 12.2386 62.5 15 62.5H65C67.7614 62.5 70 60.2614 70 57.5V22.5C70 19.7386 67.7614 17.5 65 17.5Z"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10 37.5H30C30 40.1522 31.0536 42.6957 32.9289 44.5711C34.8043 46.4464 37.3478 47.5 40 47.5C42.6522 47.5 45.1957 46.4464 47.0711 44.5711C48.9464 42.6957 50 40.1522 50 37.5H70"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10 27.5H70"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7_6145">
                    <rect width="80" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
