import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { BackButton } from '../../Features/partner-portal/BackButton';
import { Typography } from '../../Components/Typography';
import styles from './index.module.scss';
import TextField from '../../Components/TextField/TextField';
import { Button2 } from '../../Components/Button2';
import { FileInput, FileInputRef } from '../../Components/FileInput/FileInput';
import { useRef } from 'react';

const schema = yup.object().shape({
    contactEmail: yup.string().email('Must be a valid email').required('Field is required'),
    ticketTitle: yup.string().required('Field is required'),
    description: yup.string().required('Field is required'),
    attachment: yup.mixed(),
});

interface SupportData {
    contactEmail: string;
    ticketTitle: string;
    description: string;
    attachment: File | null;
}

const SupportPage = () => {
    const fileRef = useRef<FileInputRef>(null);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        resetField,
    } = useForm<SupportData>({
        // @ts-ignore
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<SupportData> = (data) => {
        const formData = new FormData();
        formData.append('email', data.contactEmail);
        formData.append('title', data.ticketTitle);
        formData.append('description', data.description);

        if (data.attachment) {
            formData.append('attachment', data.attachment);
        }

        resetField('contactEmail');
        resetField('ticketTitle');
        resetField('description');
        setValue('attachment', null);
        fileRef.current?.reset();
    };

    return (
        <main className={styles.support}>
            <div className={styles.container}>
                <div>
                    <BackButton />
                    <div className={styles.aside}>
                        <div className={styles.asideContent}>
                            <Typography variant="heading1" component="h1">
                                Contact Support
                            </Typography>
                            <Typography>
                                If you need help, create a support ticket, and our experts will
                                assist you as soon as possible. We're here to ensure a smooth
                                experience.
                            </Typography>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.formContainer}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                required
                                label="Contact Email"
                                {...register('contactEmail')}
                                error={errors.contactEmail?.message}
                            />
                            <TextField
                                required
                                label="Ticket Title"
                                {...register('ticketTitle')}
                                error={errors.ticketTitle?.message}
                            />
                            <TextField
                                required
                                label="Description"
                                multiline
                                rows={5}
                                error={errors.description?.message}
                                {...register('description')}
                            />
                            <FileInput
                                ref={fileRef}
                                label="Attachment"
                                acceptedTypes={['jpg', 'jpeg', 'png']}
                                error={errors.attachment?.message}
                                onFileSelect={(file) => {
                                    setValue('attachment', file);
                                }}
                            />
                            <Button2 color="secondary" type="submit">
                                Submit Ticket
                            </Button2>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default SupportPage;
