import './index.scss';

import { useTranslation } from 'react-i18next';
import WrapperSection from '../../../../Components/WrapperSection';
import { useRef } from 'react';
import useHandleAnimation from '../../../../Hooks/use-handle-animation';
import { AiIcon } from '../../../../Icons/ai-icon';
import { theme } from '../../../../Style/theme';
import TryOnFr from '../../../../Assets/Img/Experience/fr/size-prediction-fr.webp';
import TryOn from '../../../../Assets/Img/Experience/en/size-prediction-en.webp';
import TryOnES from '../../../../Assets/Img/Experience/es/size-prediction-es.webp';
import TryOnIT from '../../../../Assets/Img/Experience/it/size-prediction-it.webp';

import { motion } from 'framer-motion';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';
import WrapperImage from '../../../../Components/WrapperImage';

const AccurateSizingAndFit = () => {
    const { t } = useTranslation();
    const { windowWidth } = useWindowDimensions();

    const videoRef = useRef<HTMLVideoElement>(null);

    const onScroll = (isVisible: boolean) => {
        try {
            if (isVisible) {
                videoRef?.current?.play();
            } else {
                if (videoRef.current) {
                    videoRef?.current?.pause();
                    videoRef.current.currentTime = 0;
                }
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    useHandleAnimation('.accurate-sizing-video', null, onScroll);

    return (
        <div className="AccurateSizingAndFit">
            <div className="wrapper-content">
                <WrapperImage
                    className="accurate-sizing-image"
                    src={{
                        fr: TryOnFr,
                        en: TryOn,
                        es: TryOnES,
                        it: TryOnIT,
                    }}
                    alt="AI accurate sizing and fit"
                />
                {/* <WrapperVideo
                    alt="AI accurate sizing and fit video"
                    classNameOnVideo="accurate-sizing-video"
                    videoRef={videoRef}
                    muted={true}
                    src={{
                        en: {
                            desktop: {
                                mp4: require('../../../../Assets/MP4/ai-accurate-animation.mp4'),
                                webm: require('../../../../Assets/MP4/ai-accurate-animation.webm'),
                            },
                            mobile: {
                                mp4: require('../../../../Assets/MP4/ai-accurate-animation.mp4'),
                                webm: require('../../../../Assets/MP4/ai-accurate-animation.webm'),
                            },
                        },
                        it: {
                            desktop: {
                                mp4: require('../../../../Assets/MP4/ai-accurate-animation-it.mp4'),
                                webm: require('../../../../Assets/MP4/ai-accurate-animation-it.webm'),
                            },
                            mobile: {
                                mp4: require('../../../../Assets/MP4/ai-accurate-animation.mp4'),
                                webm: require('../../../../Assets/MP4/ai-accurate-animation.webm'),
                            },
                        },
                        es: {
                            desktop: {
                                mp4: require('../../../../Assets/MP4/ai-accurate-animation-es.mp4'),
                                webm: require('../../../../Assets/MP4/ai-accurate-animation-es.webm'),
                            },
                            mobile: {
                                mp4: require('../../../../Assets/MP4/ai-accurate-animation.mp4'),
                                webm: require('../../../../Assets/MP4/ai-accurate-animation.webm'),
                            },
                        },
                        fr: {
                            desktop: {
                                mp4: require('../../../../Assets/MP4/ai-accurate-animation-fr.mp4'),
                                webm: require('../../../../Assets/MP4/ai-accurate-animation-fr.webm'),
                            },
                            mobile: {
                                mp4: require('../../../../Assets/MP4/ai-accurate-animation.mp4'),
                                webm: require('../../../../Assets/MP4/ai-accurate-animation.webm'),
                            },
                        },
                    }}
                /> */}

                <div className="info">
                    <motion.div
                        className="top"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false }}
                        transition={{
                            marginTop: {
                                duration: 2,
                            },
                        }}
                        variants={{
                            hidden: { marginTop: 1 },
                            visible: {
                                marginTop: windowWidth <= 724 ? 1 : windowWidth <= 1000 ? 290 : 315,
                            },
                        }}
                    >
                        <AiIcon fill={theme['white']} />
                        <div className="text-body-medium text-white-alt subtitle">
                            {t('ExperienceAccurateSection.accurate_title')}
                        </div>
                        <WrapperSection.Title className="capitalize text-white">
                            {t('ExperienceAccurateSection.accurate_subtitle')}
                        </WrapperSection.Title>
                    </motion.div>

                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false }}
                        transition={{
                            opacity: {
                                duration: 2,
                            },
                            scale: {
                                duration: 0.2,
                            },
                        }}
                        variants={{
                            visible: { opacity: 1, scale: 1 },
                            hidden: { opacity: 0, scale: 0 },
                        }}
                        className="bottom"
                    >
                        <div
                            className="text-body text-description"
                            dangerouslySetInnerHTML={{
                                __html: t('ExperienceAccurateSection.accurate_p1'),
                            }}
                        />
                        <div className="text-body-small text-white disclaimer">
                            {t('HomePage.how_it_works_disclaimer')}
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default AccurateSizingAndFit;
