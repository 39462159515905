export const XCircleIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="81"
            viewBox="0 0 80 81"
            fill="none"
        >
            <g clipPath="url(#clip0_141_10586)">
                <path
                    opacity="0.2"
                    d="M40.0039 70.1484C56.5724 70.1484 70.0039 56.717 70.0039 40.1484C70.0039 23.5799 56.5724 10.1484 40.0039 10.1484C23.4354 10.1484 10.0039 23.5799 10.0039 40.1484C10.0039 56.717 23.4354 70.1484 40.0039 70.1484Z"
                    fill="black"
                />
                <path
                    d="M50.0039 30.1484L30.0039 50.1484"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M30.0039 30.1484L50.0039 50.1484"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M40.0039 70.1484C56.5724 70.1484 70.0039 56.717 70.0039 40.1484C70.0039 23.5799 56.5724 10.1484 40.0039 10.1484C23.4354 10.1484 10.0039 23.5799 10.0039 40.1484C10.0039 56.717 23.4354 70.1484 40.0039 70.1484Z"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_141_10586">
                    <rect
                        width="80"
                        height="80"
                        fill="white"
                        transform="translate(0.00390625 0.148438)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
