import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { PortalHeader } from './PortalHeader';
import styles from './styles/portal-layout.module.scss';
import LayoutFooter from '../Components/layout-footer';
import { SupportDesktop } from './SupportDesktop';
import { OptionSEO } from '../default-layout';

interface PortalLayoutProps {
    children: React.ReactNode;
    options?: OptionSEO;
}

export const PortalLayout = ({ children, options }: PortalLayoutProps) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className={styles.portalLayout}>
            <Helmet>
                <title>{options?.title ?? 'SpreeAI'}</title>
            </Helmet>
            <PortalHeader />
            <SupportDesktop />
            <div className={styles.mainPortalContent}>{children}</div>
            <LayoutFooter />
        </div>
    );
};
