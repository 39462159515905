import Service from '..';

export interface GetBillingPortal {
    partnerId: string;
    returnURL: string;
}

export interface GetBillingPortalResponse {
    url: 'string';
}

export interface Invoice {
    partner_id: string;
    partner_name: string;
    id: string;
    status: string;
    date: string;
    description: string;
    amount_due: number;
    link: string;
}

export interface GetInvoicesResponse {
    invoices: Invoice[];
}

interface BillingServiceInterface {}

class BillingService extends Service implements BillingServiceInterface {
    public async getBillingPortal({
        partnerId,
        returnURL,
    }: GetBillingPortal): Promise<GetBillingPortalResponse> {
        const response = await this.get(
            `v1/partner/${partnerId}/billing-portal?returnurl=${returnURL}`
        );

        if (!response || !response.ok) {
            throw new Error('An error ocurred while opening your billing portal');
        }

        return response.json();
    }

    public async getBillingInvoices({
        partnerId,
    }: {
        partnerId: string;
    }): Promise<GetInvoicesResponse> {
        const response = await this.get(`v1/partner/${partnerId}/invoices`);

        if (!response || !response.ok) {
            throw new Error('An error ocurred while fetching your invoices');
        }

        return response.json();
    }
}

export default BillingService;
