import { Typography } from '../../Components/Typography';
import styles from './styles/status-badge.module.scss';
import classNames from 'classnames';

export const StatusBadge = ({ status }: { status: 'incomplete' | 'completed' | 'processing' }) => {
    const statusStyles = {
        incomplete: styles.incomplete,
        completed: styles.completed,
        processing: styles.processing,
    };

    return (
        <div className={classNames([styles.statusBadge, statusStyles[status]])}>
            <Typography>{status}</Typography>
        </div>
    );
};
