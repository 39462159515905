import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    Table,
    useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { useState } from 'react';

import { Typography } from '../../Components/Typography';
import { Garment, GetGarmentsResponse } from '../../Services/Garment';
import { useGetGarments } from './queries';
import { DebouncedInput } from './DebouncedInput';
import styles from './styles/garments-table.module.scss';
import { useStores } from '../../Hooks/use-stores';
import { FolderOpenIcon } from './FolderOpenIcon';
import { StatusBadge } from './StatusBadge';
import { UseQueryResult } from '@tanstack/react-query';
import { XCircleIcon } from '../billing/XCircleIcon';
import { LoadingSpinner } from '../../Components/LoadingSpinner';

const columnHelper = createColumnHelper<Garment>();

const status = ['processing', 'completed', 'incomplete'] as const;
const randomIndex = () => Math.floor(Math.random() * status.length);

const columns = [
    columnHelper.accessor('title', {
        cell: (row) => (
            <Typography className={styles.name}>{row.getValue().toLowerCase()}</Typography>
        ),
        header: () => <Typography className={styles.header}>Name</Typography>,
    }),
    columnHelper.accessor('available', {
        cell: (row) => {
            return <StatusBadge status={status[randomIndex()]} />;
        },
        header: () => <Typography className={styles.header}>Status</Typography>,
    }),
    columnHelper.accessor('updated', {
        cell: (row) => {
            const date = row.getValue() ? row.getValue() : row.row.original.created;
            return (
                <Typography className={styles.name}>{`${format(date, 'MMM d')}, ${format(
                    date,
                    'hh:mm aaa'
                )}`}</Typography>
            );
        },
        header: () => <Typography className={styles.header}>Date Modified</Typography>,
    }),
];

export const GarmentsTable = () => {
    const { userStore } = useStores();
    const [keyword, setKeyword] = useState('');

    const garmentsQuery = useGetGarments({
        partner: userStore.userInfo.partner_id,
        keyword,
    });

    const table = useReactTable({
        data: garmentsQuery.data?.garments || [],
        columns,
        manualFiltering: true,
        getCoreRowModel: getCoreRowModel(),
    });

    const handleKeywordChange = (value: string) => {
        setKeyword(value);
    };

    return (
        <div className={styles.myGarments}>
            <div className={styles.garmentsHeader}>
                <div>
                    <Typography>
                        <strong>My Garments</strong>
                    </Typography>
                </div>
                <div>
                    <DebouncedInput
                        className={styles.searchInput}
                        onChange={(value) => {
                            handleKeywordChange(value);
                        }}
                        value={keyword}
                        placeholder="Search"
                    />
                </div>
            </div>
            <div className={styles.garmentsTable}>
                <table className={styles.table}>
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th key={header.id} className={styles.tableHeader}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef.header,
                                                  header.getContext()
                                              )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        <TableBody table={table} garmentsQuery={garmentsQuery} />
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const EmptyState = () => {
    return (
        <div className={styles.emptyState}>
            <div>
                <FolderOpenIcon />
                <Typography variant="heading1" component="h2">
                    No Assets Yet
                </Typography>
                <Typography>
                    You’ll be able to access any processing and status updates for your garments
                    here.
                </Typography>
                <Typography>Schedule a meeting above to get started!</Typography>
            </div>
        </div>
    );
};

interface TableBodyProps {
    table: Table<Garment>;
    garmentsQuery: UseQueryResult<GetGarmentsResponse, Error>;
}

const TableBody = ({ table, garmentsQuery }: TableBodyProps) => {
    if (garmentsQuery.isError) {
        return (
            <tr>
                <td>
                    <div className={styles.errorContainer}>
                        <div>
                            <XCircleIcon />
                            <Typography variant="heading1" component="h4">
                                Error Occurred
                            </Typography>
                            <Typography variant="body1" component="p">
                                We’ve encountered an error while retrieving your data. Please
                                refresh your window or contact support for additional support.
                            </Typography>
                        </div>
                    </div>
                </td>
            </tr>
        );
    }

    if (garmentsQuery.isPending) {
        return (
            <tr>
                <td>
                    <div className={styles.loadingContianer}>
                        <LoadingSpinner />
                    </div>
                </td>
            </tr>
        );
    }

    if (table.getRowModel().rows.length === 0) {
        return (
            <tr>
                <td>
                    <EmptyState />
                </td>
            </tr>
        );
    }

    return (
        <>
            {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className={styles.row}>
                    {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className={styles.cell}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                    ))}
                </tr>
            ))}
        </>
    );
};
