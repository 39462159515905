export const InvoiceIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
        >
            <g clipPath="url(#clip0_7_6159)">
                <path
                    opacity="0.2"
                    d="M52.5 62.5V32.5H70V60C70 60.663 69.7366 61.2989 69.2678 61.7678C68.7989 62.2366 68.163 62.5 67.5 62.5H52.5Z"
                    fill="black"
                />
                <path
                    d="M20 17.5H12.5C11.1739 17.5 9.90215 18.0268 8.96447 18.9645C8.02678 19.9021 7.5 21.1739 7.5 22.5C7.5 23.8261 8.02678 25.0979 8.96447 26.0355C9.90215 26.9732 11.1739 27.5 12.5 27.5H17.5C18.8261 27.5 20.0979 28.0268 21.0355 28.9645C21.9732 29.9021 22.5 31.1739 22.5 32.5C22.5 33.8261 21.9732 35.0979 21.0355 36.0355C20.0979 36.9732 18.8261 37.5 17.5 37.5H8.75"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15 15V17.5"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15 37.5V40"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M30 17.5H70V60C70 60.663 69.7366 61.2989 69.2678 61.7678C68.7989 62.2366 68.163 62.5 67.5 62.5H12.5C11.837 62.5 11.2011 62.2366 10.7322 61.7678C10.2634 61.2989 10 60.663 10 60V47.5"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M32.5 32.5H70"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M25 47.5H70"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M52.5 32.5V62.5"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7_6159">
                    <rect width="80" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
