import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Service from '../../Services';
import StateService, { UpdateStateRequest } from '../../Services/State';

interface UseGenDeepLink {
    partner_id: string;
}

export const useGenDeepLink = ({ partner_id }: UseGenDeepLink) =>
    useQuery({
        queryKey: ['genDeepLink'],
        queryFn: async () => {
            const response = await new Service().post('v1/deeplinks/code', {
                partner_id: 'protea',
                parameters: {},
                size: 200,
            });

            if (!response || !response.ok) {
                throw new Error('Failed to generate deep link');
            }

            return response.blob();
        },
    });

export const useGetState = ({ partnerId }: { partnerId: string }) =>
    useQuery({
        queryKey: ['state', partnerId],
        queryFn: () => new StateService().getState({ partnerId }),
        enabled: !!partnerId,
    });

export const useUpdateState = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (body: UpdateStateRequest) => new StateService().updateState(body),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['state'] });
        },
    });
};
