import { useQuery } from '@tanstack/react-query';
import BillingService, { GetBillingPortal } from '../../Services/Billing';

/**
 * Get Invoices
 */

export const useGetInvoices = ({ partnerId }: { partnerId: string }) =>
    useQuery({
        queryKey: ['invoices', partnerId],
        queryFn: () =>
            new BillingService().getBillingInvoices({
                partnerId,
            }),
        enabled: !!partnerId,
    });

/**
 * Get Billing Portal URL
 */

export const useGetBillingPortalUrl = ({ partnerId, returnURL }: GetBillingPortal) =>
    useQuery({
        queryKey: ['billingPortalUrl', partnerId],
        queryFn: async () =>
            new BillingService().getBillingPortal({
                partnerId,
                returnURL,
            }),
        enabled: false,
    });
