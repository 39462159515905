import styles from './index.module.scss';
import { LoadingSpinner } from '../LoadingSpinner';

export const LoadingPage = () => {
    return (
        <div className={styles.loading}>
            <LoadingSpinner />
        </div>
    );
};
