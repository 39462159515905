import { UseQueryResult } from '@tanstack/react-query';
import { format } from 'date-fns';

import { Button2 } from '../../Components/Button2';
import { Typography } from '../../Components/Typography';
import { TabIDs, TABS } from './constants';
import { GetInvoicesResponse, Invoice } from '../../Services/Billing';
import { InvoiceIcon } from './InvoiceIcon';

import styles from './styles/invoices-table.module.scss';
import { InvoiceStatus } from './InvoiceStatus';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    Table,
    useReactTable,
} from '@tanstack/react-table';
import { BillingTabs } from './BillingTabs';
import { useState } from 'react';
import { XCircleIcon } from './XCircleIcon';
import classNames from 'classnames';
import { LoadingSpinner } from '../../Components/LoadingSpinner';

interface InvoicesTableProps {
    invoicesQuery: UseQueryResult<GetInvoicesResponse, Error>;
}

const columnHelper = createColumnHelper<Invoice>();

const columns = [
    columnHelper.accessor('date', {
        cell: (row) => (
            <Typography className={styles.name}>
                {format(row.getValue(), 'MMMM dd, yyyy')}
            </Typography>
        ),
        header: () => <Typography className={styles.header}>Date</Typography>,
    }),
    columnHelper.accessor('description', {
        cell: (row) => <Typography className={styles.name}>Personalized Demo (Monthly)</Typography>,
        header: () => <Typography className={styles.header}>Description</Typography>,
    }),
    columnHelper.accessor('amount_due', {
        cell: (row) => (
            <Typography className={styles.name}>${row.getValue().toFixed(2)}</Typography>
        ),
        header: () => <Typography className={styles.header}>Total</Typography>,
    }),
    columnHelper.accessor('status', {
        cell: (row) => <InvoiceStatus status={row.getValue()} />,
        header: () => <Typography className={styles.header}>Status</Typography>,
    }),
    columnHelper.accessor('link', {
        cell: (row) => (
            <Button2 className={styles.button} variant="outlined" color="secondary" asChild>
                <a href={row.getValue()} target="_blank" rel="noreferrer">
                    {row.row.original.status === 'paid' ? 'Payment History' : 'View Invoice'}
                </a>
            </Button2>
        ),
        header: () => <Typography className={styles.header}></Typography>,
    }),
];

export const InvoicesTable = ({ invoicesQuery }: InvoicesTableProps) => {
    const [activeTab, setActiveId] = useState(TABS[0].id);

    const table = useReactTable({
        data: invoicesQuery.data?.invoices || [],
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            globalFilter: activeTab,
        },
        onGlobalFilterChange: setActiveId,
        globalFilterFn: (rows, id, value) => {
            if (value === 'paid') {
                return rows.original.status === 'paid';
            }

            if (value === 'due') {
                return rows.original.status === 'open';
            }

            return true;
        },
    });

    const handleSetActiveTab = (id: TabIDs) => {
        table.setGlobalFilter(id);
    };

    return (
        <>
            <BillingTabs
                tabs={TABS}
                handleSetActiveTab={handleSetActiveTab}
                activeTab={activeTab}
            />
            <div
                className={classNames(styles.invoicesTable, {
                    [styles.minHeight]:
                        invoicesQuery.isError || table.getRowModel().rows.length === 0,
                })}
            >
                <table className={styles.table}>
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th key={header.id} className={styles.tableHeader}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef.header,
                                                  header.getContext()
                                              )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        <TableBody table={table} invoicesQuery={invoicesQuery} />
                    </tbody>
                </table>
            </div>
        </>
    );
};

const EmptyState = () => {
    return (
        <div className={styles.emptyState}>
            <div>
                <InvoiceIcon />
                <Typography variant="heading1" component="h4" style={{ fontWeight: 400 }}>
                    No Record of Invoices
                </Typography>
                <Typography variant="body1" component="p">
                    No payments have been made yet, so there are currently no invoice records to
                    display.
                </Typography>
            </div>
        </div>
    );
};

interface TableBodyProps {
    table: Table<Invoice>;
    invoicesQuery: UseQueryResult<GetInvoicesResponse, Error>;
}

const TableBody = ({ table, invoicesQuery }: TableBodyProps) => {
    if (invoicesQuery.isError) {
        return (
            <tr>
                <td>
                    <div className={styles.errorContainer}>
                        <div>
                            <XCircleIcon />
                            <Typography variant="heading1" component="h4">
                                Error Occurred
                            </Typography>
                            <Typography variant="body1" component="p">
                                We’ve encountered an error while retrieving your data. Please
                                refresh your window or contact support for additional support.
                            </Typography>
                        </div>
                    </div>
                </td>
            </tr>
        );
    }

    if (invoicesQuery.isPending) {
        return (
            <tr>
                <td>
                    <div className={styles.loadingContianer}>
                        <LoadingSpinner />
                    </div>
                </td>
            </tr>
        );
    }

    if (table.getRowModel().rows.length === 0) {
        return (
            <tr>
                <td>
                    <EmptyState />
                </td>
            </tr>
        );
    }

    return (
        <>
            {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className={styles.row}>
                    {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className={styles.cell}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                    ))}
                </tr>
            ))}
        </>
    );
};
