import { Check, DeviceMobileSpeaker } from '@phosphor-icons/react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';

import CONSTANTS from '../../Constants';
import styles from './index.module.scss';
import { Link } from '../../Components/Link';
import { Typography } from '../../Components/Typography';
import { Button2 } from '../../Components/Button2';
import { BackButton } from '../../Features/partner-portal/BackButton';
import { useStores } from '../../Hooks/use-stores';
import { useCustomPath } from '../../Hooks/use-custom-path';
import { useGenDeepLink, useGetState } from '../../Features/partner-portal/queries';
import { LoadingPage } from '../../Components/LoadingPage';
import { VideoPlayer } from '../../Components/VideoPlayer';

export const Phase1 = () => {
    const { userStore } = useStores();
    const customPath = useCustomPath({ where: CONSTANTS.SCREEN.INTERNAL_TESTING });
    const stateQuery = useGetState({ partnerId: userStore.userInfo.partner_id });

    if (stateQuery.isLoading) {
        return <LoadingPage />;
    }

    if (stateQuery.error) {
        return <div>Error: {stateQuery.error.message}</div>;
    }

    const steps = [
        {
            title: 'Acknowledgement',
            completed: Boolean(stateQuery.data?.agreed_to_terms),
            inProgress: Boolean(!stateQuery.data?.agreed_to_terms),
        },
        {
            title: 'Pending Items',
            completed: Boolean(stateQuery.data?.approved_for_testing),
            inProgress: Boolean(
                stateQuery.data?.agreed_to_terms && !stateQuery.data?.approved_for_testing
            ),
        },
        {
            title: 'Access App',
            completed: Boolean(null),
            inProgress: Boolean(stateQuery.data?.approved_for_testing),
        },
    ];

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <BackButton path={customPath} />
                <div className={styles.content}>
                    <div className={styles.left}>
                        <div>
                            <Typography variant="heading1" component="h1">
                                Internal Testing
                            </Typography>
                            {steps[2].inProgress ? (
                                <Typography>
                                    You’ve officially unlocked access to Internal
                                    Testing—congratulations! Dive into the app now to experience
                                    your personalized setup and see your brand brought to life like
                                    never before!
                                </Typography>
                            ) : (
                                <Typography>
                                    This is your chance to bring your brand to life with our
                                    technology! By using your own garments and input data, you’ll
                                    experience a personalized, hands-on preview of how our solutions
                                    can seamlessly integrate with your vision.
                                </Typography>
                            )}
                        </div>
                        {steps[2].inProgress ? <InternalTestingQRCode /> : <Steps steps={steps} />}
                        {steps[0].inProgress && (
                            <Button2 variant="contained" color="secondary" asChild>
                                <Link pathConfig={{ where: CONSTANTS.SCREEN.ACKNOWLEDGEMENT }}>
                                    Continue
                                </Link>
                            </Button2>
                        )}
                        {steps[1].inProgress && (
                            <Button2 variant="contained" color="secondary" asChild>
                                <Link pathConfig={{ where: CONSTANTS.SCREEN.PENDING_ITEMS }}>
                                    Continue
                                </Link>
                            </Button2>
                        )}
                        {steps[2].inProgress && (
                            <Button2 variant="outlined" color="secondary" asChild>
                                <Link pathConfig={{ where: CONSTANTS.SCREEN.PARTNER_PORTAL }}>
                                    Return to Main Portal
                                </Link>
                            </Button2>
                        )}
                    </div>
                    <div>
                        {steps[2].inProgress ? (
                            <div className={styles.testingImageContainer}>
                                <VideoPlayer
                                    muted
                                    showControls={false}
                                    src="https://superb-actor-37d8b69f79.media.strapiapp.com/admin_user_qr_code_video_7a8facff65.mp4"
                                />
                                <div className={styles.testingImageText}>
                                    <div className={styles.testingImageTextHeader}>
                                        <DeviceMobileSpeaker size={24} />
                                        <Typography component="h4">
                                            Immersive Mobile Experience
                                        </Typography>
                                    </div>
                                    <Typography>
                                        Scan the QR code and log in with your account to being
                                        exploring.
                                    </Typography>
                                </div>
                            </div>
                        ) : (
                            <VideoPlayer
                                muteStorageKey={CONSTANTS.LOCALSTORAGE_KEY.TESTING_VIDEO_IS_MUTED}
                                src="https://superb-actor-37d8b69f79.media.strapiapp.com/Internal_Testing_Video_Encoded_e6e1515522.mp4"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

interface StepProps {
    steps: {
        title: string;
        completed: boolean;
        inProgress: boolean;
    }[];
}

const Steps = ({ steps }: StepProps) => {
    return (
        <ul className={styles.steps}>
            {steps.map(({ title, completed, inProgress }, index) => {
                const stepStyles = classNames([
                    styles.step,
                    {
                        [styles.completed]: completed,
                        [styles.inProgress]: inProgress,
                    },
                ]);
                return (
                    <li key={index} className={stepStyles}>
                        <span>
                            {completed ? (
                                <Check fill="#ffffff" weight="bold" size={18} strokeWidth={2} />
                            ) : (
                                index + 1
                            )}
                        </span>
                        <Typography>{title}</Typography>
                    </li>
                );
            })}
        </ul>
    );
};

const InternalTestingQRCode = observer(() => {
    const { userStore } = useStores();
    const { data, error } = useGenDeepLink({ partner_id: userStore.userInfo.id });

    if (error) {
        toast.error(error.message);
    }

    const createImageSource = (blob: any) => {
        if (!blob) return '';

        return URL.createObjectURL(blob);
    };

    return (
        <div className={styles.qrCodeContainer}>
            <img src={createImageSource(data)} alt="QR code" className={styles.qrCode} />
            <Typography>Scan to Access the App</Typography>
        </div>
    );
});
