import classNames from 'classnames';

import { STATUS } from './constants';
import styles from './styles/invoice-status.module.scss';

interface InvoiceStatusProps {
    status: string;
}

export const InvoiceStatus = ({ status }: InvoiceStatusProps) => {
    const isPaid = status === 'paid';

    return (
        <div className={classNames([styles.tab, isPaid ? styles.paid : styles.unpaid])}>
            {STATUS[status]}
        </div>
    );
};
