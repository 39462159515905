import { useGetBillingPortalUrl } from '../../Features/billing/queries';

import { Button2 } from '../../Components/Button2';
import { useStores } from '../../Hooks/use-stores';
import styles from './styles/billing-portal-button.module.scss';

interface BillingPortalButtonProps {
    children: React.ReactNode;
}

export const BillingPortalButton = ({ children }: BillingPortalButtonProps) => {
    const { userStore } = useStores();
    const { data, refetch, isLoading } = useGetBillingPortalUrl({
        partnerId: userStore.userInfo.partner_id,
        returnURL: window.location.href,
    });

    const handleVisitBillingPortal = () => {
        refetch();
    };

    if (data?.url) {
        window.location.href = data.url;
    }

    return (
        <Button2
            disabled={isLoading}
            variant="outlined"
            color="secondary"
            onClick={handleVisitBillingPortal}
        >
            <span className={styles.text} style={{ opacity: isLoading ? 0 : 1 }}>
                {children}
            </span>
            <span className={styles.loading} style={{ opacity: isLoading ? 1 : 0 }}>
                Loading...
            </span>
        </Button2>
    );
};
