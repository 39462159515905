import { toast } from 'react-toastify';
import { Typography } from '../../Components/Typography';
import { useGenDeepLink } from '../../Features/partner-portal/queries';
import { useStores } from '../../Hooks/use-stores';
import styles from './employee-portal.module.scss';
import { Binoculars, Camera, CoatHanger, RocketLaunch } from '@phosphor-icons/react';
import classNames from 'classnames';

export const EmployeePortal = () => {
    const { userStore } = useStores();
    const { first_name, last_name, partner_state, partner_id } = userStore.userInfo;

    const { data, error } = useGenDeepLink({ partner_id });

    if (error) {
        toast.error(error.message);
    }

    const createImageSource = (blob: any) => {
        if (!blob) return '';

        return URL.createObjectURL(blob);
    };

    return (
        <div className={styles.employeePortal}>
            <div className={styles.left}>
                <Typography component="h1" variant="heading1" style={{ textAlign: 'center' }}>
                    Hello, {first_name} {last_name}!
                </Typography>
                <div className={styles.qrCodeContainer}>
                    {!partner_state.approved_for_testing && !partner_state.agreed_to_terms ? (
                        <Typography style={{ textAlign: 'center', maxWidth: '400px' }}>
                            To access the app, please agree to the terms and conditions from your
                            brand's partner portal.
                        </Typography>
                    ) : (
                        <>
                            <img
                                src={createImageSource(data)}
                                alt="QR code"
                                className={styles.qrCode}
                            />
                            <Typography
                                className={styles.subheading}
                                style={{ textAlign: 'center' }}
                            >
                                Scan to Access the App!
                            </Typography>
                        </>
                    )}
                </div>
            </div>
            <div
                className={classNames(styles.right, {
                    [styles.removeBg]: partner_state.approved_for_testing,
                })}
            >
                <Typography
                    component="h1"
                    variant="heading1"
                    style={{ visibility: 'hidden' }}
                    aria-disabled
                >
                    Hello, {first_name} {last_name}!
                </Typography>
                {!partner_state.approved_for_testing && (
                    <div className={styles.wrapper}>
                        <Typography component="h3" className={styles.subheading}>
                            In the app, you can:{' '}
                        </Typography>
                        <ul className={styles.list}>
                            <li>
                                <Camera fill="#2EB67D" size={32} weight="duotone" />
                                <div>
                                    <Typography className={styles.subtitle}>
                                        Submit Your Photo
                                    </Typography>
                                    <Typography>
                                        Get started by taking and submitting a photo of yourself
                                        using the QR code on the left.
                                    </Typography>
                                </div>
                            </li>
                            <li>
                                <CoatHanger fill="#2EB67D" size={32} weight="duotone" />
                                <div>
                                    <Typography className={styles.subtitle}>
                                        Submit Your Photo
                                    </Typography>
                                    <Typography>
                                        Get started by taking and submitting a photo of yourself
                                        using the QR code on the left.
                                    </Typography>
                                </div>
                            </li>
                            <li>
                                <Binoculars fill="#2EB67D" size={32} weight="duotone" />
                                <div>
                                    <Typography className={styles.subtitle}>
                                        Submit Your Photo
                                    </Typography>
                                    <Typography>
                                        Get started by taking and submitting a photo of yourself
                                        using the QR code on the left.
                                    </Typography>
                                </div>
                            </li>
                        </ul>
                        <Typography>
                            We'll notify you via email as soon as the app is <span>updated</span>{' '}
                            with your Internal Testing experience. Stay tuned!
                        </Typography>
                    </div>
                )}
                {partner_state.approved_for_testing && (
                    <div className={classNames([styles.cardContainer, styles.bgGreen])}>
                        <div className={styles.content}>
                            <RocketLaunch fill="#2EB67D" size={80} weight="duotone" />
                            <Typography component="h3" className={styles.subheading}>
                                Your Internal Testing Is Now Ready!
                            </Typography>
                            <div>
                                <Typography>
                                    You can now try on your branded garments, explore the features,
                                    and see everything in action.
                                </Typography>
                                <Typography>
                                    Log in to the app now and start testing — your brand, your way!
                                </Typography>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
