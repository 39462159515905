import { ArrowLeft } from '@phosphor-icons/react';
import classNames from 'classnames';
import { format } from 'date-fns';

import { useGetInvoices } from '../../Features/billing/queries';
import { useStores } from '../../Hooks/use-stores';
import { Button2 } from '../../Components/Button2';
import { Typography } from '../../Components/Typography';
import { Link } from '../../Components/Link';
import CONSTANTS from '../../Constants';
import { BillingPortalButton } from '../../Features/billing/BillingPortalButton';
import { CardHolderIcon } from '../../Features/billing/CardHolderIcon';
import styles from './index.module.scss';
import { InvoicesTable } from '../../Features/billing/InvoicesTable';
import { InvoiceStatus } from '../../Features/billing/InvoiceStatus';

export const Billing = () => {
    const { userStore } = useStores();
    const invoicesQuery = useGetInvoices({ partnerId: userStore.userInfo.partner_id });

    return (
        <main className={styles.main}>
            <div className={styles.container}>
                <div>
                    <Link
                        pathConfig={{ where: CONSTANTS.SCREEN.PARTNER_PORTAL }}
                        className={styles.back}
                    >
                        <ArrowLeft size={32} fill="#000" />
                        Back
                    </Link>
                </div>
                <div className={styles.header}>
                    <Typography variant="heading1">Billing & Payments</Typography>
                </div>
                <div className={styles.billingCards}>
                    <div className={classNames(styles.card)}>
                        <Typography component="h4">Your Upcoming Payment</Typography>
                        <UpcomingPaymentCard invoicesQuery={invoicesQuery} />
                    </div>
                    <div className={classNames(styles.card, styles.paymentMethod)}>
                        <div className={styles.cardHeader}>
                            <CardHolderIcon />
                            <Typography component="h4">
                                Need To Update Your Payment Method?
                            </Typography>
                            <Typography>
                                Keep your payments running smoothly by updating your payment method
                                here.
                            </Typography>
                        </div>
                        <BillingPortalButton>Manage Payment Method</BillingPortalButton>
                    </div>
                </div>
                <InvoicesTable invoicesQuery={invoicesQuery} />
            </div>
        </main>
    );
};

interface UpcomingPaymentCardProps {
    invoicesQuery: ReturnType<typeof useGetInvoices>;
}

export const UpcomingPaymentCard = ({ invoicesQuery }: UpcomingPaymentCardProps) => {
    if (invoicesQuery.data?.invoices && invoicesQuery.data.invoices.length > 0) {
        return (
            <>
                <div className={styles.upcomingDetails}>
                    <div className={styles.price}>
                        $ {invoicesQuery.data?.invoices[0].amount_due.toFixed(2)}
                    </div>
                    <div className={styles.upcomingDetailsFooter}>
                        <InvoiceStatus status={invoicesQuery.data?.invoices[0].status} />
                        <Typography>
                            Due : {format(invoicesQuery.data?.invoices[0].date, 'MMMM dd, yyyy')}
                        </Typography>
                    </div>
                </div>
                <Button2 className={styles.whiteButton} asChild>
                    <a href={invoicesQuery.data?.invoices[0].link} target="_blank" rel="noreferrer">
                        View Invoice
                    </a>
                </Button2>
            </>
        );
    }

    return (
        <>
            <div className={styles.upcomingDetails}>
                <div className={styles.price}>$ {0}</div>
                <div className={styles.upcomingDetailsFooter}>
                    <Typography>There are no upcoming payments at the moment.</Typography>
                </div>
            </div>
            <Button2 className={classNames(styles.whiteButton, styles.disabled)}>
                View Invoice
            </Button2>
        </>
    );
};
