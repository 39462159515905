import { useRef, useEffect } from 'react';

import styles from './styles/billing-tabs.module.scss';
import classNames from 'classnames';
import type { Tab, TabIDs } from './constants';

interface BillingTabsProps {
    activeTab: string;
    handleSetActiveTab: (id: TabIDs) => void;
    tabs: Tab[];
}

export const BillingTabs = ({ activeTab, handleSetActiveTab, tabs }: BillingTabsProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const activeTabElementRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const container = containerRef.current;

        if (activeTab && container) {
            const activeTabElement = activeTabElementRef.current;

            if (activeTabElement) {
                const { offsetLeft, offsetWidth } = activeTabElement;

                const clipLeft = offsetLeft;
                const clipRight = offsetLeft + offsetWidth;
                container.style.clipPath = `inset(0 ${Number(
                    100 - (clipRight / container.offsetWidth) * 100
                ).toFixed()}% 0 ${Number(
                    (clipLeft / container.offsetWidth) * 100
                ).toFixed()}% round 10px)`;
            }
        }
    }, [activeTab, activeTabElementRef, containerRef]);

    return (
        <div className={styles.tabs}>
            <div className={styles.tabsWrapper}>
                <div className={styles.tabsRoot}>
                    <div className={styles.tabsList}>
                        {tabs.map((tab) => (
                            <button
                                ref={activeTab === tab.id ? activeTabElementRef : null}
                                key={`trigger-${tab.id}`}
                                data-tab={tab.id}
                                onClick={() => handleSetActiveTab(tab.id)}
                                className={styles.tab}
                                style={{
                                    WebkitTapHighlightColor: 'transparent',
                                }}
                            >
                                <span className={styles.tabTitle}>{tab.title}</span>
                            </button>
                        ))}
                    </div>
                    <div aria-hidden className={styles.clipPathContainer} ref={containerRef}>
                        <div className={classNames([styles.tabsList, styles.overlay])}>
                            {tabs.map((tab) => (
                                <button
                                    key={`trigger-${tab.id}`}
                                    data-tab={tab.id}
                                    onClick={() => handleSetActiveTab(tab.id)}
                                    className={styles.tab}
                                    style={{
                                        WebkitTapHighlightColor: 'transparent',
                                    }}
                                >
                                    <span className={styles.tabTitle}>{tab.title}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.line} />
        </div>
    );
};
