import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CloseIcon } from '../../../Icons/close-icon';
import { LogoBrandSmall } from '../logo-brand-small';
import { ChevronBottomIcon } from '../../../Icons/chevron-bottom-icon';
import { ChevronTopIcon } from '../../../Icons/chevron-top-icon';
import { LockIcon } from '../../../Icons/lock-icon';
import { I_NAV_OPTIONS, NAV_OPTIONS } from './config';
import PartnerIcon from '../../../Icons/partner-icon';
import { theme } from '../../../Style/theme';
import { useStores } from '../../../Hooks/use-stores';
import ButtonPartnerAccessDashboard from './Components/button-partner-access-dashboard';
import { Link } from '../../../Components/Link';
import HeaderSelectLanguage from './Components/select-language';
import CONSTANTS from '../../../Constants';
import { getKeyByValueRecursive } from '../../../Utils/object';
import Copydeck from '../../../i18n/Copydeck';
import { isCurrentPath } from './helpers';

import './layout-header-mobile.scss';

interface LayoutHeaderMobileProps {
    onClose: () => void;
}

const LayoutHeaderMobile: React.FC<LayoutHeaderMobileProps> = ({ onClose }) => {
    const [isOpen, setIsOpen] = useState<any>({
        experience: false,
        aboutUs: false,
        supportRessources: false,
    });

    const [alreadySelected, setAlreadySelected] = useState<any>(false);

    const { t } = useTranslation();
    const location = useLocation();

    const { userStore, languageStore } = useStores();

    const handleOnClose = () => onClose();

    const myChildrenHasSelected = (navOption: I_NAV_OPTIONS, currentPath: string | undefined) => {
        if (!navOption.content) return false;
        let result = false;

        navOption.content.forEach((content) => {
            if (content.href === currentPath) result = true;
        });

        if (result && !alreadySelected) {
            setAlreadySelected(true);
            setIsOpen((prev: any) => ({
                ...prev,
                [navOption.title]: prev?.[navOption.title] ? false : true,
            }));
        }
        return result;
    };

    return (
        <div className="LayoutHeaderMobile">
            <div className="menu-burger-close" onClick={handleOnClose}>
                <CloseIcon fill="#2b2b2b" />
            </div>

            <div className="mobile-nav-wrapper">
                <div className="mobile-nav-content">
                    <div className="content-actions">
                        <a className="container-LogoBrandSmall" href="/">
                            <LogoBrandSmall />
                        </a>

                        {NAV_OPTIONS.map((navOption) => {
                            const isNotClickable = navOption?.href === '#';
                            const keyRoutesOfPath = getKeyByValueRecursive(
                                CONSTANTS.ROUTES,
                                location.pathname
                            );

                            const isCurrentSelected =
                                isCurrentPath(
                                    navOption,
                                    languageStore.currentLanguage,
                                    location.pathname
                                ) ||
                                myChildrenHasSelected(navOption, keyRoutesOfPath?.split('.').pop());

                            return (
                                <div>
                                    <div
                                        className={`navigation-one-line ${
                                            isNotClickable ? '' : 'cursor-pointer'
                                        }`}
                                    >
                                        {isNotClickable ? (
                                            <p
                                                className={`navigation-title text-primary-font ${
                                                    isCurrentSelected ? 'nav-item-selected' : ''
                                                } cursor-pointer`}
                                                onClick={() => {
                                                    setIsOpen((prev: any) => ({
                                                        ...prev,
                                                        [navOption.title]: prev?.[navOption.title]
                                                            ? false
                                                            : true,
                                                    }));
                                                }}
                                            >
                                                {t(navOption.title)}
                                            </p>
                                        ) : navOption.type === 'external' ? (
                                            <a
                                                href={navOption.href}
                                                className={`navigation-title text-primary-font ${
                                                    isCurrentSelected ? 'nav-item-selected' : ''
                                                }`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {t(navOption.title)}
                                            </a>
                                        ) : (
                                            <Link
                                                pathConfig={{ where: navOption.href }}
                                                className={`navigation-title text-primary-font ${
                                                    isCurrentSelected ? 'nav-item-selected' : ''
                                                }`}
                                                rel="noopener noreferrer"
                                            >
                                                {t(navOption.title)}
                                            </Link>
                                        )}

                                        {navOption.content && (
                                            <div
                                                onClick={() => {
                                                    setIsOpen((prev: any) => ({
                                                        ...prev,
                                                        [navOption.title]: prev?.[navOption.title]
                                                            ? false
                                                            : true,
                                                    }));
                                                }}
                                            >
                                                {isOpen?.[navOption?.title || ''] ? (
                                                    <ChevronTopIcon
                                                        fill={
                                                            isCurrentSelected
                                                                ? theme[
                                                                      'surface-brand-rest-primary'
                                                                  ]
                                                                : theme['black']
                                                        }
                                                    />
                                                ) : (
                                                    <ChevronBottomIcon
                                                        fill={
                                                            isCurrentSelected
                                                                ? theme[
                                                                      'surface-brand-rest-primary'
                                                                  ]
                                                                : theme['black']
                                                        }
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    {navOption.content && isOpen?.[navOption?.title || '']
                                        ? navOption.content.map((content) => {
                                              const isCurrentSelected = isCurrentPath(
                                                  content,
                                                  languageStore.currentLanguage,
                                                  location.pathname
                                              );

                                              return (
                                                  <Link
                                                      pathConfig={{ where: content.href }}
                                                      // onClick={() => nav(content.href)}
                                                      className={`text-primary-font sub-navigation-title subitem-${
                                                          content.type
                                                      } deprecated-text-title-medium ${
                                                          isCurrentSelected
                                                              ? 'nav-item-selected'
                                                              : ''
                                                      }`}
                                                  >
                                                      {t(content.title)}

                                                      {content.type === 'disabled'
                                                          ? `${'(' + t('coming_soon') + ')'}`
                                                          : null}
                                                      {content.type === 'locked' ? (
                                                          <div className="nav-subitem-locked">
                                                              <LockIcon />
                                                          </div>
                                                      ) : null}
                                                  </Link>
                                              );
                                          })
                                        : null}
                                </div>
                            );
                        })}

                        <div className="separator" />

                        {userStore.isLogin() ? (
                            <ButtonPartnerAccessDashboard
                                href={CONSTANTS.SCREEN.PARTNER_PORTAL}
                                icon={<PartnerIcon fill={theme['surface-brand-rest-tertiary']} />}
                                label={t('HeaderNav.partner_access')}
                            />
                        ) : (
                            <ButtonPartnerAccessDashboard
                                href={CONSTANTS.SCREEN.CREATE_ACCOUNT}
                                label={t('HeaderNav.partner_access_no_login')}
                                icon={<PartnerIcon fill={theme['surface-brand-rest-tertiary']} />}
                            />
                        )}

                        {userStore.isLogin() ? null : (
                            <Link pathConfig={{ where: CONSTANTS.SCREEN.LOGIN }}>
                                <div className="text-body-medium-primary-font-bold cta-login text-element-brand-rest-primary">
                                    {Copydeck.loginLoginSpace}
                                </div>
                            </Link>
                        )}
                        <div className="container-header-select-language">
                            <HeaderSelectLanguage />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LayoutHeaderMobile;
