import { TLEADER } from './type';
import LeaderImageJohnImah from './leader_john_imah.webp';
import LeaderImageBobDavidson from './leader_bob_davidson.webp';
import LeaderImageNaomiCampbell from './leader_naomi_campbell.webp';
import LeaderImageLarryLudo from './leader_larry_ruvo_small.jpeg';

import LeaderImageJohnImahSmall from './leader_john_imah_small.webp';
import LeaderImageBobDavidsonSmall from './leader_bob_davidson_small.webp';
import LeaderImageNaomiCampbellSmall from './leader_naomi_campbell_small.webp';
// import LeaderImageLarryLudoSmall from './leader_larry_ludo_small.webp';

namespace CONSTANTS {
    export namespace SCREEN {
        export const HOME = 'HOME';
        export const SUPPORT = 'SUPPORT';
        export const ABOUT_US = 'ABOUT_US';
        export const TERMS_OF_SERVICE = 'TERMS_OF_SERVICE';
        export const PRIVACY_POLICY = 'PRIVACY_POLICY';
        export const PRIVACY_NOTICE = 'PRIVACY_NOTICE';
        export const CONTACT_US = 'CONTACT_US';
        export const LOGIN = 'LOGIN';
        export const PASSWORD_RESET_SENT = 'PASSWORD_RESET_SENT';
        export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
        export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';
        export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
        export const CREATE_ACCOUNT_FLOW = 'CREATE_ACCOUNT_FLOW';
        export const EMAIL_CONFIRMED = 'EMAIL_CONFIRMED';
        export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
        export const PARTNER_ASKS = 'PARTNER_ASKS';
        export const DPA = 'DPA';
        export const LIFE_AT_SPREEAI = 'LIFE_AT_SPREEAI';
        export const DASHBOARD = 'DASHBOARD';
        export const BIOMETRIC_CONSENT = 'BIOMETRIC_CONSENT';
        export const CAREERS = 'CAREERS';
        export const JOBS = 'JOBS';
        export const FAQ = 'FAQ';
        export const BLOG = 'BLOG';
        export const BLOG_ID = 'BLOG_ID';
        export const IMPACTS_AND_INCLUSIONS = 'IMPACTS_AND_INCLUSIONS';
        export const BILLING = 'BILLING';
        export const PARTNER_PORTAL = 'PARTNER_PORTAL';
        export const WELCOME = 'WELCOME';
        export const MEET_OUR_TEAM = 'MEET_OUR_TEAM';
        export const ENGINEERING = 'ENGINEERING';
        export const MANAGE_ASSESTS = 'MANAGE_ASSESTS';
        export const ACKNOWLEDGEMENT = 'ACKNOWLEDGEMENT';
        export const PHASE_ONE = 'PHASE_ONE';
        export const PENDING_ITEMS = 'PENDING_ITEMS';
        export const INTERNAL_TESTING = 'INTERNAL_TESTING';
        export const EMPLOYEE = 'EMPLOYEE';
    }

    export const ROUTES = {
        FR: {
            HOME: '/fr',
            SUPPORT: '/fr/support',
            ABOUT_US: '/fr/a-propos-de-nous',
            TERMS_OF_SERVICE: '/fr/term-de-service',
            PRIVACY_POLICY: '/fr/politique-de-confidentialite',
            CONTACT_US: '/fr/contactez-nous',
            LOGIN: '/fr/login',
            PASSWORD_RESET_SENT: '/fr/reussis-changement-de-mot-de-passe',
            SET_NEW_PASSWORD: '/fr/changer-mot-de-passe',
            NEW_PASSWORD_SUCCESS: '/fr/nouveau-mot-de-passe-reussi',
            CREATE_ACCOUNT: '/fr/creez-un-compte',
            CREATE_ACCOUNT_FLOW: '/fr/flow-creation-compte',
            EMAIL_CONFIRMED: '/fr/confirmer-email',
            PARTNER_ASKS: '/fr/questions-partenaires',
            DPA: '/fr/dpa',
            LIFE_AT_SPREEAI: '/fr/vie-chez-spreeai',
            DASHBOARD: '/fr/accueil',
            BIOMETRIC_CONSENT: '/fr/consentement-biometrique',
            CAREERS: '/fr/carrieres',
            JOBS: '/fr/emplois',
            FAQ: '/fr/faq',
            BLOG: '/fr/blog',
            BLOG_ID: '/fr/blog/:id',
            IMPACTS_AND_INCLUSIONS: '/fr/impact-et-inclusion',
            PRIVACY_NOTICE: '/fr/avis-de-confidentialite',
            BILLING: '/fr/facturation',
            PARTNER_PORTAL: '/fr/portail',
            PHASE_ONE: '/fr/phase-1',
            PENDING_ITEMS: '/fr/elements-en-attente',
            INTERNAL_TESTING: '/fr/test-interne',
            MEET_OUR_TEAM: '/fr/notre-equipe',
            ENGINEERING: '/fr/ingenierie',
            ACCOUNT_DELETED: '/fr/compte-supprime',
            WELCOME: '/fr/bienvenue',
            MANAGE_ASSESTS: '/fr/gerer-les-actifs',
            ACKNOWLEDGEMENT: '/fr/reconnaissance',
            EMPLOYEE: '/fr/employe',
        },

        EN: {
            HOME: '/',
            SUPPORT: '/support',
            ABOUT_US: '/about-us',
            TERMS_OF_SERVICE: '/terms-of-service',
            PRIVACY_POLICY: '/privacy-policy',
            CONTACT_US: '/contact-us',
            LOGIN: '/login',
            PASSWORD_RESET_SENT: '/password-reset-sent',
            SET_NEW_PASSWORD: '/set-new-password',
            NEW_PASSWORD_SUCCESS: '/new-password-success',
            CREATE_ACCOUNT: '/create-account',
            CREATE_ACCOUNT_FLOW: '/create-account-flow',
            EMAIL_CONFIRMED: '/confirm-email',
            PARTNER_ASKS: '/partner-asks',
            DPA: '/dpa',
            LIFE_AT_SPREEAI: '/life-at-spreeai',
            DASHBOARD: '/dashboard',
            BIOMETRIC_CONSENT: '/biometric-consent',
            CAREERS: '/careers',
            JOBS: '/jobs',
            FAQ: '/faq',
            BLOG: '/blog',
            BLOG_ID: '/blog/:id',
            IMPACTS_AND_INCLUSIONS: '/impact-and-inclusion',
            PRIVACY_NOTICE: '/privacy-notice',
            BILLING: '/billing',
            PARTNER_PORTAL: '/portal',
            PHASE_ONE: '/phase-1',
            PENDING_ITEMS: '/pending-items',
            INTERNAL_TESTING: '/internal-testing',
            MEET_OUR_TEAM: '/meet-our-team',
            ENGINEERING: '/engineering',
            ACCOUNT_DELETED: '/account-deleted',
            WELCOME: '/welcome',
            MANAGE_ASSESTS: '/manage-assets',
            ACKNOWLEDGEMENT: '/acknowledgement',
            EMPLOYEE: '/employee',
        },

        IT: {
            HOME: '/it',
            SUPPORT: '/it/supporto',
            ABOUT_US: '/it/chi-siamo',
            TERMS_OF_SERVICE: '/it/termini-di-servizio',
            PRIVACY_POLICY: '/it/informativa-sulla-privacy',
            CONTACT_US: '/it/contattaci',
            LOGIN: '/it/accedi',
            PASSWORD_RESET_SENT: '/it/password-reset-inviata',
            SET_NEW_PASSWORD: '/it/imposta-nuova-password',
            NEW_PASSWORD_SUCCESS: '/it/nuova-password-impostata',
            CREATE_ACCOUNT: '/it/crea-account',
            CREATE_ACCOUNT_FLOW: '/it/flow-di-registrazione',
            EMAIL_CONFIRMED: '/it/email-confermata',
            PARTNER_ASKS: '/it/partner-richiede',
            DPA: '/it/dpa',
            LIFE_AT_SPREEAI: '/it/vita-a-spreeai',
            DASHBOARD: '/it/dashboard',
            BIOMETRIC_CONSENT: '/it/accesso',
            CAREERS: '/it/carriere',
            JOBS: '/it/lavori',
            FAQ: '/it/faq',
            BLOG: '/it/blog',
            BLOG_ID: '/it/blog/:id',
            IMPACTS_AND_INCLUSIONS: '/it/impatti-e-inclusioni',
            PRIVACY_NOTICE: '/it/privacy-notice',
            BILLING: '/it/fatturazione',
            PARTNER_PORTAL: '/it/portale',
            INTERNAL_TESTING: '/it/test-interno',
            PHASE_ONE: '/it/fase-1',
            PENDING_ITEMS: '/it/elementi-in-attesa',
            MEET_OUR_TEAM: '/our-team',
            ENGINEERING: '/it/ingegneria',
            ACCOUNT_DELETED: '/it/account-cancellato',
            WELCOME: '/it/benvenuto',
            MANAGE_ASSESTS: '/it/gestire-gli-asset',
            ACKNOWLEDGEMENT: '/it/riconoscimento',
            EMPLOYEE: '/it/impiegato',
        },

        ES: {
            HOME: '/es',
            SUPPORT: '/es/apoyo',
            ABOUT_US: '/es/sobre-nosotros',
            TERMS_OF_SERVICE: '/es/terminos-de-servicio',
            PRIVACY_POLICY: '/es/politica-de-privacidad',
            CONTACT_US: '/es/contactenos',
            LOGIN: '/es/acceso',
            PASSWORD_RESET_SENT: '/es/restablecer-contrasena-enviada',
            SET_NEW_PASSWORD: '/es/establecer-nueva-contrasena',
            NEW_PASSWORD_SUCCESS: '/es/nueva-contrasena-exito',
            CREATE_ACCOUNT: '/es/crear-una-cuenta',
            CREATE_ACCOUNT_FLOW: '/es/crear-flujo-de-cuenta',
            EMAIL_CONFIRMED: '/es/correo-electronico-confirmado',
            PARTNER_ASKS: '/es/partner-pregunta',
            DPA: '/es/dpa',
            LIFE_AT_SPREEAI: '/es/vida-en-spreeai',
            DASHBOARD: '/es/dashboard',
            BIOMETRIC_CONSENT: '/es/consentimiento-biometrico',
            CAREERS: '/es/carriere',
            JOBS: '/es/empleos',
            FAQ: '/es/faq',
            BLOG: '/es/blog',
            BLOG_ID: '/es/blog/:id',
            IMPACTS_AND_INCLUSIONS: '/es/impacto-e-inclusione',
            PRIVACY_NOTICE: '/es/aviso-de-privacidad',
            BILLING: '/es/facturacion',
            PARTNER_PORTAL: '/es/portal',
            INTERNAL_TESTING: '/es/prueba-interna',
            PHASE_ONE: '/es/fase-1',
            PENDING_ITEMS: '/es/elementos-pendientes',
            MEET_OUR_TEAM: '/es/nuestro-equipo',
            ENGINEERING: '/es/ingenieria',
            ACCOUNT_DELETED: '/is/cuenta-eliminada',
            WELCOME: '/es/bienvenido',
            MANAGE_ASSESTS: '/es/administrar-activos',
            ACKNOWLEDGEMENT: '/es/reconocimiento',
            EMPLOYEE: '/es/empleado',
        },
    };
    export const LEADERS: TLEADER[] = [
        {
            img: LeaderImageJohnImah,
            imgSmall: LeaderImageJohnImahSmall,
            fullName: 'John Imah',
            jobTitleNameKey: 'Leader.leader1_jobtitle',
            descriptionKey: 'Leader.leader1_description',
            linkedinUrl: 'https://www.linkedin.com/in/johnimah',
        },
        {
            img: LeaderImageNaomiCampbell,
            imgSmall: LeaderImageNaomiCampbellSmall,
            fullName: 'Naomi Campbell',
            jobTitleNameKey: 'Leader.leader4_jobtitle',
            descriptionKey: 'Leader.leader4_description',
        },
        {
            img: LeaderImageBobDavidson,
            imgSmall: LeaderImageBobDavidsonSmall,
            fullName: 'Bob Davidson',
            jobTitleNameKey: 'Leader.leader3_jobtitle',
            descriptionKey: 'Leader.leader3_description',
        },
        {
            img: LeaderImageLarryLudo,
            imgSmall: LeaderImageLarryLudo,
            fullName: 'Larry Ruvo',
            jobTitleNameKey: 'Leader.leader5_jobtitle',
            descriptionKey: 'Leader.leader5_description',
        },
    ];

    export const ERROR_CODE = {
        NOT_FOUND: 404,
        FORBIDDEN: 403,
        ACCOUNT_NOT_CONFIRMED: 'E001002',
        ACCOUNT_NOT_APPROVED: 'E001003',
    };

    export const INSTAGRAM_URL = 'https://www.instagram.com/spreeai/';
    export const TWITTER_URL = 'https://twitter.com/spreeai';
    export const LINKEDIN_URL = 'https://www.linkedin.com/company/spreeai';
    export const YOUTUBE_URL = 'https://www.youtube.com/channel/UCwqmYq9Cp-JE4iOU-LtRTPg';
    export const TIKTOK_URL = 'https://www.tiktok.com/@spreeai';
    export const THREADS_URL = 'https://www.threads.net/@spreeai';
    export const BLUE_SKY_URL = 'https://bsky.app/profile/spreeai.bsky.social';

    export namespace LOCALSTORAGE_KEY {
        export const ACCESS_TOKEN = '@access_token';
        export const REFRESH_TOKEN = '@refresh_token';
        export const MAIL = '@mail';
        export const LANG = 'lang';
        export const COOKIES_ACCEPTED = '@cookies-accepted';
        export const COOKIES_NECESSARY_ACCEPTED = '@cookies-necessary-accepted';
        export const COOKIES_PERFORMANCE_ACCEPTED = '@cookies-performance-accepted';
        export const CLOSE_ANNOUNCEMENT = '@close_announcement';
        export const TESTING_VIDEO_IS_MUTED = '@testing_video_is_muted';
        export const MEET_WITH_US_VIDEO_IS_MUTED = '@meet_with_us_video_is_muted';
    }

    export namespace MOMENT_FORMAT {
        export const DD_MM_YYYY = 'DD/MM/YYYY';
        export const MMMM_DD_COMMA_YYYY = 'MMMM DD, YYYY';
    }

    export namespace API_URL {
        export const DEV = 'https://api.dev.spreeai.com/';
        export const STAGE = 'https://api.stage.spreeai.com/';
        export const PROD = 'https://api.spreeai.com/';
    }
}

export default CONSTANTS;
