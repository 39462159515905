export type TabIDs = 'all' | 'due' | 'paid';

export interface Tab {
    id: TabIDs;
    title: string;
}

export const TABS: Tab[] = [
    { id: 'all', title: 'All invoices' },
    { id: 'due', title: 'Due' },
    { id: 'paid', title: 'Payment History' },
];

export const STATUS: { [key: string]: string } = {
    paid: 'Paid',
    open: 'Due',
};
