import { observer } from 'mobx-react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { CaretDown } from '@phosphor-icons/react';
import classNames from 'classnames';

import { LogoBrand } from '../Components/logo-brand';
import CONSTANTS from '../../Constants';
import { Link } from '../../Components/Link';
import styles from './styles/portal-header.module.scss';
import { useStores } from '../../Hooks/use-stores';
import { SpreeLogoSmall } from '../../Icons/SpreeLogoSmall';
import useCustomNavigation from '../../Hooks/use-custom-navigation';
import { useCustomPath } from '../../Hooks/use-custom-path';
import { useLocation } from 'react-router-dom';

export const PortalHeader = () => {
    const welcomePath = useCustomPath({ where: CONSTANTS.SCREEN.WELCOME });
    const portalHomePath = useCustomPath({ where: CONSTANTS.SCREEN.PARTNER_PORTAL });
    const employeePath = useCustomPath({ where: CONSTANTS.SCREEN.EMPLOYEE });
    const location = useLocation();

    const hideNavItems =
        location.pathname === welcomePath ||
        location.pathname === portalHomePath ||
        location.pathname === employeePath;

    return (
        <header className={styles.header}>
            <div className={styles.container}>
                <Link
                    pathConfig={{ where: CONSTANTS.SCREEN.PARTNER_PORTAL }}
                    className={styles.logo}
                >
                    <LogoBrand />
                </Link>
                <div className={styles.right}>
                    {!hideNavItems && (
                        <nav className={styles.nav}>
                            <ul>
                                <li>
                                    <Link pathConfig={{ where: CONSTANTS.SCREEN.PARTNER_PORTAL }}>
                                        Home
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    )}

                    <Dropdown />
                </div>
            </div>
        </header>
    );
};

const Dropdown = observer(() => {
    const { tokenStore, userStore } = useStores();
    const nav = useCustomNavigation();

    const onClickLogout = () => {
        tokenStore.clearTokens();
        userStore.clearUserInfo();
        nav(CONSTANTS.SCREEN.HOME);
    };

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <button className={styles.dropdownTrigger}>
                    <div>
                        <span>
                            <SpreeLogoSmall />
                        </span>
                        <span>{userStore.userInfo.first_name}</span>
                    </div>
                    <CaretDown className={styles.caret} fill="#ffffff" size={20} />
                </button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content className={styles.dropdownContent}>
                <DropdownMenu.Item
                    className={classNames([styles.dropdownItem, styles.logout])}
                    asChild
                >
                    <button onClick={onClickLogout}>Log Out</button>
                </DropdownMenu.Item>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
});
