import React, { FunctionComponent, useEffect } from 'react';
import Router from './Router';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { useTranslation } from 'react-i18next';
import { useStores } from './Hooks/use-stores';
import { SupportedLanguage } from './Stores/LanguageStore';
import { observer } from 'mobx-react-lite';
import GlobalLoadingContainer from './Components/GlobalLoadingContainer/global-loading-container';
import 'react-responsive-modal/styles.css'; //this must be imported before WelcomeModal
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { loadScript } from './Utils/object';
import ModalManagement from './Components/ModalManagement';

const router = createBrowserRouter([{ path: '*', element: <Router /> }]);

const App: FunctionComponent = observer(() => {
    const { i18n } = useTranslation();
    const { languageStore } = useStores();

    const updateChatBot = () => {
        const determineNewValue = (
            justEn?: boolean
        ): {
            platform_id: string;
            user_id: string;
            chatbot_id: string;
        } => {
            if (justEn || languageStore.currentLanguage === SupportedLanguage.EN) {
                return {
                    platform_id: 'd406e774-0399-47a6-9b7f-65bb521312c7',
                    user_id: 'f315f8ce-58df-4eca-9490-d029ad37dc38',
                    chatbot_id: 'a4bdf17a-3421-4b0d-9b3b-c1e086585267',
                };
            }

            if (languageStore.currentLanguage === SupportedLanguage.FR) {
                return {
                    platform_id: 'e713dcf1-11cd-46ab-8858-7a36015a2cef',
                    user_id: 'f315f8ce-58df-4eca-9490-d029ad37dc38',
                    chatbot_id: '5355e446-9cfa-452d-b1cd-e7c66cc47266',
                };
            }

            if (languageStore.currentLanguage === SupportedLanguage.ES) {
                return {
                    platform_id: 'eea58e16-9e43-4747-bb5f-f16f012f6cb7',
                    user_id: 'f315f8ce-58df-4eca-9490-d029ad37dc38',
                    chatbot_id: '2b6a2c29-4709-4852-8f19-24b7eb10c62f',
                };
            }

            if (languageStore.currentLanguage === SupportedLanguage.IT) {
                return {
                    platform_id: 'a7de8fc2-5758-42b9-bd55-86e86903dd74',
                    user_id: 'f315f8ce-58df-4eca-9490-d029ad37dc38',
                    chatbot_id: '2b8ccb9a-4d37-442c-82d5-57619bf3047b',
                };
            }

            return determineNewValue(true);
        };

        const metaTag = document.querySelector('chat-bot[name="chatbot"]');
        if (metaTag) {
            const { platform_id, user_id, chatbot_id } = determineNewValue();
            metaTag.setAttribute('platform_id', platform_id);
            metaTag.setAttribute('user_id', user_id);
            metaTag.setAttribute('chatbot_id', chatbot_id);

            const metaTagCpy = metaTag.cloneNode(true);
            (metaTag as any).parentNode.replaceChild(metaTagCpy, metaTag);

            loadScript('https://cdn.chatsimple.ai/chat-bot-loader.js', {
                name: 'chat-bot',
                ...determineNewValue(),
            });
        }
    };

    useEffect(() => {
        //Triggered on language change or first render.
        switch (languageStore.currentLanguage) {
            case SupportedLanguage.EN:
                updateChatBot();
                i18n.changeLanguage('en');
                break;
            case SupportedLanguage.FR:
                updateChatBot();
                i18n.changeLanguage('fr');
                break;
            case SupportedLanguage.IT:
                updateChatBot();
                i18n.changeLanguage('it');
                break;
            case SupportedLanguage.ES:
                updateChatBot();
                i18n.changeLanguage('es');
                break;
            case null:
                languageStore.setFirstRender();
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageStore.currentLanguage, i18n]);

    return (
        <div className="App">
            <HelmetProvider>
                <RouterProvider router={router} />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <GlobalLoadingContainer />

                <ModalManagement />
            </HelmetProvider>
        </div>
    );
});

export default App;
